import { BPEnum, CategoryType, LanguageEnum, PaginationType, ServiceType } from '@/types';
import styles from './offers.module.scss';
import { SliderWrapperComp, AppLinkComp } from 'components';
import ServiceCardComp from './serviceCard/serviceCard.index';
import { Button, Divider, Spin, Steps, message } from 'antd';
import { TbArrowLeft, TbArrowRight } from 'react-icons/tb';
import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from '@/context';
import { API, PATH } from '@/data';
import { RestAPI, useBP, useRealTimeState } from '@/scripts';
import DragAbleComp from '../../dragAble/dragAble.index';
import AnimatedTextComp from '../animatedText/animatedText.index';
const duration = 5000;
const steps = 25;
function OffersTabContentComp(props: {
  services?: PaginationType<ServiceType>;
  categories?: CategoryType[];
}) {
  const bp = useBP();
  const {
    langText,
    location
  } = useContext(GlobalContext);
  const lang = langText.landing2;
  const isFA = langText.lang === LanguageEnum.fa;
  const [activeSlide, setActiveSlide] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<Partial<CategoryType>>();
  const [messageApi, contextHolder] = message.useMessage();
  const [servicesList, setServicesList] = useState<PaginationType<ServiceType> | undefined>(props.services);
  const [serviceCategories, setServiceCategories] = useState(props.categories);
  const sliderLayout = [{
    max_width: BPEnum.SM,
    perView: 1.3,
    spacing: 12
  }, {
    max_width: BPEnum.MD,
    perView: 2.3,
    spacing: 12
  }, {
    max_width: BPEnum.LG,
    perView: 2.3
  }, {
    max_width: BPEnum.XL,
    perView: 2.9
  }, {
    max_width: BPEnum.XXL,
    perView: 3.9,
    origin: 0.04
  }];
  const [hintIndex, setHintIndex] = useState(0);
  const timeout = useRef<NodeJS.Timeout>();
  const progress = useRealTimeState(0);
  const categories: Partial<CategoryType>[] = [{
    title: langText.categoryList.all_category,
    slug: '',
    priority: Math.max(...(serviceCategories ?? []).map(i => i.priority ?? 0)) + 1
  }, ...(serviceCategories ?? [])];
  useEffect(() => {
    // start over slider
    setActiveSlide(0);
    if (!serviceCategories) RestAPI.get<PaginationType<CategoryType>>(API.categories.list, {
      lang: langText.lang,
      page_size: 100
    }).then(res => setServiceCategories(res.data.data));
    if (!servicesList) RestAPI.get<PaginationType<ServiceType>>(API.services.index, {
      lang: langText.lang,
      page_size: 20,
      order: 'most_priority',
      brand__is_active: true,
      is_active: true
    }).then(res => setServicesList(res.data));
  }, []);
  useEffect(() => {
    if (selectedCategory === undefined) setServicesList(props.services);else {
      setServicesList(undefined);
      RestAPI.get<PaginationType<ServiceType>>(API.services.index, {
        lang: langText.lang,
        category_and_descendants: selectedCategory?.slug ?? '',
        order: 'most_priority',
        brand__is_active: true,
        is_active: true,
        page_size: 20
      }).then(res => setServicesList(res.data)).catch(() => messageApi.open({
        type: 'error',
        content: lang.errorMessage
      }));
    }
  }, [selectedCategory]);
  useEffect(() => {
    if (progress.get() >= 1) {
      progress.set(0);
      setHintIndex(hintIndex + 1 >= lang.offersHint.steps.length ? 0 : hintIndex + 1);
    }
  }, [progress.dep]);
  useEffect(() => {
    progress.set(0);
    setHintIndex(0);
    clearInterval(timeout.current);
    timeout.current = setInterval(() => {
      progress.set(progress.get() + 1 / steps);
    }, duration / steps);
  }, []);
  const categoryHandler = (item: Partial<CategoryType>) => {
    setSelectedCategory(item);
    const tabEl = document.getElementById(`landing-category-${item.id}`);
    const scrolled = tabEl?.parentElement?.scrollLeft ?? 0;
    const dim = tabEl?.getBoundingClientRect();
    const width = dim?.width ?? 0;
    const left = dim?.left ?? 0;
    const diff = window.innerWidth / 2 - left;
    tabEl?.parentElement?.scrollTo({
      left: scrolled - diff + width / 2,
      behavior: 'smooth'
    });
  };
  const innerData = categories.filter(i => selectedCategory?.parent ? i.parent?.slug === selectedCategory.parent.slug : selectedCategory?.slug ? i.parent?.slug === selectedCategory.slug : false);
  const sliderLength = servicesList?.data.length ?? 0;
  return <div className={`${styles['offers']}`} data-sentry-component="OffersTabContentComp" data-sentry-source-file="offers.index.tsx">
            {contextHolder}
            <div className={styles['animation']}>
                <AnimatedTextComp textIndex={hintIndex} texts={lang.offersHint.steps.map(i => i.desc)} data-sentry-element="AnimatedTextComp" data-sentry-source-file="offers.index.tsx" />
            </div>
            <Steps className={styles['steps']} current={hintIndex} percent={progress.dep * 100} labelPlacement="vertical" items={lang.offersHint.steps.map(i => ({
      title: i.title
    }))} onChange={e => {
      setHintIndex(e);
      progress.set(0);
    }} data-sentry-element="Steps" data-sentry-source-file="offers.index.tsx" />
            <Divider className="mb-4-i d-pre-md-none-i" data-sentry-element="Divider" data-sentry-source-file="offers.index.tsx" />
            <div className={`${styles['slider']}`}>
                {serviceCategories ? <div className={`${styles['category-list']} flex-column`}>
                        <DragAbleComp parentClassName="main-content ph-4" className={`${styles['parent-category']} d-flex align-items-center pe-3 ps-2`}>
                            {categories.sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0)).filter(i => !i.slug || !i.parent).map((item, index) => <div onClick={() => categoryHandler(item)} key={index} id={`landing-category-${item.id}`} className={`${styles['list-items']} pv-1 ${selectedCategory?.parent ? selectedCategory.parent.slug === item.slug ? styles['active'] : '' : selectedCategory?.slug ? item.slug === selectedCategory.slug ? styles['active'] : '' : item.slug === '' ? styles['active'] : ''}`}>
                                        <p className="t-400 t-dark3">{item.title}</p>
                                    </div>)}
                        </DragAbleComp>
                        {innerData.length ? <DragAbleComp parentClassName={`main-content ph-4 ${innerData.length ? 'mv-2' : ''}`} iconClassName="t-primary" className={`${styles['child-category']} d-flex align-items-center ph-3`}>
                                <span style={{
            flex: 1,
            display: 'block'
          }}></span>
                                {innerData.sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0)).map((item, index) => <div onClick={() => setSelectedCategory(item)} key={index} className={`${styles['list-items']} p-1 ${selectedCategory?.slug === item.slug ? styles['active'] : ''}`}>
                                            <p className="t-400">{item.title}</p>
                                        </div>)}
                                <span style={{
            flex: 1,
            display: 'block'
          }}></span>
                            </DragAbleComp> : null}
                    </div> : null}
                {servicesList ? <SliderWrapperComp items={servicesList.data} child={ServiceCardComp} noLoop origin={0.03} loadingHeight={315} className={`pt-4 ${styles['slider-content']}`} hideArrow={bp?.MD_LTE ? false : true} activeSlide={activeSlide} onSlideChange={e => setActiveSlide(e)} layout={sliderLayout} /> : <div className={`${styles['loading-box']} center-content w-100`}>
                        <Spin />
                    </div>}
                <div className={`${styles['arrow-btn']} center-content mt-4 gap-32`}>
                    <span onClick={() => setActiveSlide(isFA ? prev => prev > 0 ? prev - 1 : 0 : prev => prev < sliderLength - 1 ? prev + 1 : sliderLength - 1)} className={`pointer center-content d-pre-md-none ${isFA && activeSlide === 0 || !sliderLength ? styles['disabled'] : langText.lang === LanguageEnum.en && activeSlide === sliderLength - 1 ? styles['disabled'] : ''}`}>
                        {isFA ? <TbArrowRight color="#ffffff" /> : <TbArrowLeft color="#ffffff" />}
                    </span>
                    <AppLinkComp href={servicesList && servicesList.count === 0 ? PATH(langText.lang).services.index(location.slug) : PATH(langText.lang).services.category(location.slug, selectedCategory?.slug ?? '')} data-sentry-element="AppLinkComp" data-sentry-source-file="offers.index.tsx">
                        <Button loading={!servicesList} className="ph-5-i" data-sentry-element="Button" data-sentry-source-file="offers.index.tsx">
                            {servicesList && servicesList.count === 0 ? bp?.MD_LTE ? lang.seeAllService : lang.seeAllService : bp?.MD_LTE ? lang.seeAll : lang.see + ' ' + (servicesList?.count ?? '') + ' ' + lang.buy2}
                        </Button>
                    </AppLinkComp>
                    <span onClick={() => setActiveSlide(isFA ? prev => prev < sliderLength - 1 ? prev + 1 : sliderLength - 1 : prev => prev > 0 ? prev - 1 : 0)} className={`pointer center-content d-pre-md-none ${!sliderLength || isFA && activeSlide === sliderLength - 1 ? styles['disabled'] : langText.lang === LanguageEnum.en && activeSlide === 0 ? styles['disabled'] : ''}`}>
                        {isFA ? <TbArrowLeft color="#ffffff" /> : <TbArrowRight color="#ffffff" />}
                    </span>
                </div>
            </div>
        </div>;
}
export default OffersTabContentComp;