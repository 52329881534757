import {
    BrandRankingType,
    BrandType,
    CategoryType,
    HiringGenderEnum,
    HiringExperienceEnum,
    HiringType,
    HiringTypeEnum,
    HiringSalaryTypeEnum,
    HiringContractEnum,
    PaginationType,
    ServiceType,
    HiringCategoryType,
} from '@/types';

export type __BrandDetailPropsType = {
    data: BrandType;
    categories: CategoryType[];
    initialFilters: __BrandSearchParamsType;
    services?: PaginationType<ServiceType>;
    hiring?: PaginationType<HiringType>;
    hiringCategories?: HiringCategoryType[];
    rankings?: PaginationType<BrandRankingType<'detail'>>;
};

export enum __BrandSectionEnum {
    services = 'services',
    ranking = 'ranking',
    hiring = 'hiring',
}
export type __BrandFilterType = {
    ///// common
    section?: __BrandSectionEnum;
    page?: string;
    search?: string;
    order?: string;
    category?: string;
    ///// service
    starGTE?: number;
    starLTR?: number;
    //// hiring
    foreign?: number;
    gender?: HiringGenderEnum | HiringGenderEnum[];
    salaryGTE?: number;
    salaryLTE?: number;
    agreementSalary?: boolean;
    time?: HiringContractEnum | HiringContractEnum[];
    workType?: HiringTypeEnum | HiringTypeEnum[];
    experience?: HiringExperienceEnum | HiringExperienceEnum[];
    salaryType?: HiringSalaryTypeEnum | HiringSalaryTypeEnum[];
};

export type __BrandSearchParamsType = Partial<Record<keyof __BrandFilterType, string[] | string>>;
