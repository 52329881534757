'use client';

import styles from './landing.module.scss';
import Image from 'next/image';
import bgLanding from 'assets/images/landing2/bg-landing.svg';
import bgLandingMobile from 'assets/images/landing2/bg-landing-mobile.svg';
import bgSVG from 'assets/images/landing2/svg1.svg';
import { useContext } from 'react';
import { GlobalContext } from 'context';
import FAQComp from './FAQ/FAQ.index';
import { __LandingData, BTC_FLAG, CA_FLAG, IRAN_FLAG, USA_FLAG } from './landing.data';
// import AnimatedTextComp from './animatedText/animatedText.index';
import BestsComp from './bests/bests.index';
import servicesSVG from 'assets/images/landing2/servicesSVG.svg';
import { FloatButton, List, Popover } from 'antd';
import { TbLayoutGridFilled } from 'react-icons/tb';
import MainVerticalsComp from './mainVerticals/mainVerticals.index';
import { __LandingPropsType } from './landing.type';
function LandingComp(props: __LandingPropsType) {
  const {
    langText
  } = useContext(GlobalContext);
  const lang = langText.landing2;
  console.log(props.market);
  const marketSource = [...(props.market?.cad?.value ? [{
    flag1: CA_FLAG,
    title1: '1 CAD',
    flag2: IRAN_FLAG,
    title2: props.market?.cad?.value + ' IRR'
  }] : []), ...(props.market?.usd?.value ? [{
    flag1: USA_FLAG,
    title1: '1 USD',
    flag2: IRAN_FLAG,
    title2: props.market?.usd?.value + ' IRR'
  }] : []), ...(props.market?.usd?.value && props.market.cad?.value ? [{
    flag1: CA_FLAG,
    title1: '1 CAD',
    flag2: USA_FLAG,
    title2: Math.round(Number(props.market.cad.value) / Number(props.market.usd.value) * 1000) / 1000 + ' USD'
  }, {
    flag1: USA_FLAG,
    title1: '1 USD',
    flag2: CA_FLAG,
    title2: Math.round(Number(props.market.usd.value) / Number(props.market.cad.value) * 1000) / 1000 + ' CAD'
  }] : []), ...(props.market?.btc?.value && props.market.usd?.value ? [{
    flag1: BTC_FLAG,
    title1: '1 BTC',
    flag2: USA_FLAG,
    title2: Math.round(Number(props.market.btc.value) / Number(props.market.usd.value)) + ' USD'
  }] : [])];
  return <div className={`${styles['container']}`} data-sentry-component="LandingComp" data-sentry-source-file="landing.index.tsx">
            <div className={`${styles['banner']}`}>
                <img src={bgLanding.src} alt="landing" className="d-pre-md-none" />
                <img src={bgLandingMobile.src} width={600} height={544} alt="landing" className="d-md-none" />
            </div>
            <MainVerticalsComp categories={props.categories} services={props.services} serviceType={props.serviceType} houses={props.houses} events={props.events} eventCategories={props.eventCategories} hiring={props.hiring} hiringCategories={props.hiringCategories} data-sentry-element="MainVerticalsComp" data-sentry-source-file="landing.index.tsx" />
            <div className={`${styles['transition-art']} main-content`}>
                <Image src={bgSVG} alt="svg" width={1440} height={544} data-sentry-element="Image" data-sentry-source-file="landing.index.tsx" />
            </div>
            <BestsComp data-sentry-element="BestsComp" data-sentry-source-file="landing.index.tsx" />
            <div className={`${styles['services-container']} main-content`}>
                <img src={servicesSVG.src} alt="landing" width={979} height={924} className={`${styles['services-svg']}`} />
                <h2 className="t-h2 t-800 t-dark1 mb-6">{lang.serviceTitle}</h2>
                <div className={`${styles['services-layout']}`}>
                    {__LandingData(lang).map(item => <div key={item.id} className={`${styles['service-card']} d-flex align-items-center gap-24`}>
                            <span className="d-flex center-content">
                                <Image src={item.src} alt="service" width={item.size} height={item.size} />
                            </span>
                            <div>
                                <p className="t-p1 t-700 t-dark1 mb-2">{item.title}</p>
                                <p className="t-p2 t-400 t-dark3 d-pre-md-none">{item.desc}</p>
                                <p className="t-p2 t-400 t-primary d-none d-pre-md-block pointer">{lang.seeMore}</p>
                            </div>
                        </div>)}
                </div>
            </div>
            <FAQComp className={`${styles['faq']}`} data={props.faq} data-sentry-element="FAQComp" data-sentry-source-file="landing.index.tsx" />
            {marketSource.length ? <Popover placement="rightBottom" trigger="click" content={<List dataSource={marketSource} renderItem={item => <List.Item className={styles.market}>
                                    <div>
                                        <img src={item.flag2} alt="IR.IRAN" />
                                        <p>{item.title2}</p>
                                    </div>
                                    <span>=</span>
                                    <div>
                                        <p>{item.title1}</p>
                                        <img src={item.flag1} alt="IR.IRAN" />
                                    </div>
                                </List.Item>} />}>
                    <FloatButton type="default" className={styles.fab} icon={<div>
                                <TbLayoutGridFilled size={24} />
                            </div>}></FloatButton>
                </Popover> : null}
        </div>;
}
export default LandingComp;