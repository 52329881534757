import { GlobalContext } from '@/context';
import { HiringData, PATH } from '@/data';
import { HiringType } from '@/types';
import { useContext } from 'react';
import styles from './hiringCard.module.scss';
import { Divider } from 'antd';
import { TbArmchair, TbBriefcase2, TbCoin, TbMapPinFilled } from 'react-icons/tb';
import Image from 'next/image';
export default function HiringCardComp({
  item,
  className
}: {
  item: HiringType;
  className?: string;
}) {
  const {
    langText
  } = useContext(GlobalContext);
  const lang = langText.hiring;
  const img = item.brand.main_image ?? (item.brand.gallery?.length ? item.brand.gallery?.[0] : undefined);
  return <a href={PATH(langText.lang).hiring.detail(item.slug)} target="_blank" className={className ?? ''} data-sentry-component="HiringCardComp" data-sentry-source-file="hiringCard.index.tsx">
            <div className={`${styles['hiring-card']}`}>
                <h2 className="t-p1 pb-1">{item.title}</h2>
                <p className="t-400 t-p1 t-primary">{item.category?.title}</p>
                <Divider className="mv-2-i" data-sentry-element="Divider" data-sentry-source-file="hiringCard.index.tsx" />
                <div className="align-items-center mb-1 gap-8 t-dark3">
                    <TbCoin size={18} data-sentry-element="TbCoin" data-sentry-source-file="hiringCard.index.tsx" />
                    <span>
                        {item.salary ? item.salary + ' CAD' : HiringData.salary(lang).find(i => i.value === false)?.label}
                    </span>
                    <Divider type="vertical" className="m-0-i" data-sentry-element="Divider" data-sentry-source-file="hiringCard.index.tsx" />
                    <span>{HiringData.payment(lang).find(i => i.value === item.salary_type)?.label}</span>
                </div>
                <div className="align-items-center mb-1 gap-8 t-dark3">
                    <TbArmchair size={18} data-sentry-element="TbArmchair" data-sentry-source-file="hiringCard.index.tsx" />
                    <span>{HiringData.type(lang).find(i => i.value === item.type)?.label}</span>
                    <Divider type="vertical" className="m-0-i" data-sentry-element="Divider" data-sentry-source-file="hiringCard.index.tsx" />
                    <span>{HiringData.time(lang).find(i => i.value === item.contract)?.label}</span>
                </div>
                <div className="align-items-center gap-8 t-dark3">
                    <TbBriefcase2 size={18} data-sentry-element="TbBriefcase2" data-sentry-source-file="hiringCard.index.tsx" />
                    <span>{HiringData.experience(lang).find(i => i.value === item.experience)?.label}</span>
                </div>
                <Divider className="mv-2-i" data-sentry-element="Divider" data-sentry-source-file="hiringCard.index.tsx" />
                <div className={`align-items-center gap-12 ${styles['brand']}`}>
                    {img ? <Image src={img.file} alt={item.brand.title} width={50} height={50} /> : null}
                    <div>
                        <p className="t-500">{item.brand.title}</p>
                        <div className={`gap-8 align-items-center ${item.location ? '' : 'd-none'}`}>
                            <TbMapPinFilled className="t-dark4" size={18} data-sentry-element="TbMapPinFilled" data-sentry-source-file="hiringCard.index.tsx" />
                            <span className="t-dark4 t-p1">{item.location?.name}</span>
                        </div>
                    </div>
                </div>
            </div>
        </a>;
}