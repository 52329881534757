'use client';

import styles from './mainVerticals.module.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from 'context';
import { ServiceTypeEnum, LanguageEnum } from 'types';
import { __HeaderList } from '../landing.data';
import { COLORS } from 'utilities';
import { nextBrowser, useBP } from 'scripts';
import { PATH } from 'data';
import { useRouter, useSearchParams } from 'next/navigation';
import OffersTabContentComp from './offers/offers.index';
import HousingTabContentComp from './housing/housing.index';
import csSVG from 'assets/images/landing2/svg2.svg';
import csEnSVG from 'assets/images/landing2/comming_soon_en.png';
import Image from 'next/image';
import { BottomNavigationComp } from 'components';
import EventsTabContentComp from './events/events.index';
import { __MainVerticalsPropsType } from './mainVerticals.type';
import HiringTabContentComp from './hiring/hiring.index';
function MainVerticalsComp(props: __MainVerticalsPropsType) {
  const router = useRouter();
  const {
    langText
  } = useContext(GlobalContext);
  const lang = langText.landing2;
  const searchParams = useSearchParams();
  const bp = useBP();
  const isMobile = bp?.MD_LTE;
  const isFA = langText.lang === LanguageEnum.fa;
  const [isScroll, setIsScroll] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>();
  const getActiveTab = (newItem?: string) => {
    const service = newItem ?? props.serviceType;
    if (service === ServiceTypeEnum.offer) return ServiceTypeEnum.offer;
    if (service === ServiceTypeEnum.dating) return ServiceTypeEnum.dating;
    if (service === ServiceTypeEnum.housing) return ServiceTypeEnum.housing;
    if (service === ServiceTypeEnum.events) return ServiceTypeEnum.events;
    if (service === ServiceTypeEnum.hiring) return ServiceTypeEnum.hiring;
    return ServiceTypeEnum.offer;
  };
  const [activeIndex, setActiveIndex] = useState(getActiveTab());
  const onServiceClick = (item: ServiceTypeEnum) => {
    setActiveIndex(item);
    router.replace(PATH(langText.lang).home(item));
  };
  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => setIsScroll(!entry.isIntersecting));
  }, []);
  useEffect(() => {
    if (boxRef.current) observerRef.current?.observe(boxRef.current);
    return () => {
      observerRef.current?.disconnect();
    };
  }, [boxRef.current]);
  useEffect(() => {
    const item = searchParams.get('service');
    if (item) {
      setActiveIndex(s => {
        if (s !== item) nextBrowser.window?.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        return getActiveTab(item);
      });
    }
    const descriptionMetaTag: HTMLLinkElement | null = document.querySelector('link[rel="canonical"]');
    if (descriptionMetaTag) descriptionMetaTag.href = PATH(langText.lang).home(Object.values(ServiceTypeEnum).find(i => i === item) ?? ServiceTypeEnum.offer);
  }, [searchParams]);
  return <>
            <div className="main-content">
                <div className={`${styles['slider-layout']}`}>
                    <div className={`${styles['header']}`}>
                        <ul className="p-0-i m-0-i">
                            {__HeaderList(lang).map(item => <li key={item.id} onClick={() => onServiceClick(item.id)} className={`center-content gap-8 pointer ${item.id === activeIndex ? styles['active'] : ''} `}>
                                    <item.icon size={isMobile ? 28 : 24} color={item.id === activeIndex ? COLORS.primary : COLORS.dark3} />
                                    <p>{item.title}</p>
                                </li>)}
                        </ul>
                    </div>
                    <div>
                        {activeIndex === ServiceTypeEnum.offer ? <OffersTabContentComp categories={props.categories} services={props.services} /> : activeIndex === ServiceTypeEnum.housing ? <HousingTabContentComp houses={props.houses} /> : activeIndex === ServiceTypeEnum.events ? <EventsTabContentComp events={props.events} categories={props.eventCategories} /> : activeIndex === ServiceTypeEnum.hiring ? <HiringTabContentComp hiring={props.hiring} categories={props.hiringCategories} /> : <div className={`${styles['coming-soon']} center-content`}>
                                <div className="ph-3">
                                    <Image src={isFA ? csSVG : csEnSVG} alt="coming soon" width={570} height={370} />
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
            <BottomNavigationComp className={`${!isScroll ? styles['hidden'] : ''}`} activeIndex={activeIndex} setActiveIndex={onServiceClick} data-sentry-element="BottomNavigationComp" data-sentry-source-file="mainVerticals.index.tsx" />
        </>;
}
export default MainVerticalsComp;