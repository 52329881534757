import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Modal!=!/var/www/frontend/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "/var/www/frontend/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/assets/images/logo-typo-fa.svg");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/components/index.tsx");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/context/index.ts");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/scripts/storage.ts");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/scripts/useAsyncClick.ts");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/scripts/useBP.ts");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/scripts/useEventListener.ts");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/scripts/useOnBlur.ts");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/scripts/useRealTimeState.ts");
import(/* webpackMode: "eager" */ "/var/www/frontend/src/tehranto/landing2/landing.index.tsx")