'use client';

import styles from './serviceCard.module.scss';
import Image from 'next/image';
import { FaStar } from 'react-icons/fa';
import { CacheBackTypeEnum, ServiceType } from '@/types';
import { GlobalContext } from '@/context/index';
import { useContext } from 'react';
import { TbCamera } from 'react-icons/tb';
import Link from 'next/link';
import { PATH } from '@/data/index';
function ServiceCardComp({
  item,
  className
}: {
  item: ServiceType;
  className?: string;
}) {
  const lang = useContext(GlobalContext).langText.components;
  const langText = useContext(GlobalContext).langText;
  let offer = '';
  if (item.cash_back) {
    offer = item.cash_back + (item.cash_back_type === CacheBackTypeEnum.constant ? '$' : '%');
  }
  if (item.extra_service?.length) {
    if (offer.length) offer = offer + ' + ';
    offer = offer + item.extra_service?.join(' + ');
  }
  return <Link href={PATH(langText.lang).services.detail(item)} target="_blank" data-sentry-element="Link" data-sentry-component="ServiceCardComp" data-sentry-source-file="serviceCard.index.tsx">
            <div className={`${styles['service-card']} ${className ?? ''}`}>
                <div className={`${styles['image-layout']}`}>
                    {item.gallery?.length ? <Image src={item.gallery[0].file ?? ''} alt={item.gallery[0].alt ?? 'service-image'} width={265} height={200} className="w-100" /> : <div className={`w-100 h-100 center-content ${styles['empty-state']}`}>
                            <TbCamera className="t-dark3" size={48} />
                        </div>}
                    {/* {item.cash_back ? (
                        <span className="center-content t-h4 t-700 t-white">
                            {item.cash_back}
                            {item.cash_back_type === CacheBackTypeEnum.constant ? '$' : '%'}
                        </span>
                     ) : (
                        <span className={`${styles['extra-service']} d-flex center-content t-white`}>
                            <p className="t-white t-p1 t-700">{item.extra_service?.join(' + ')}</p>
                        </span>
                     )} */}
                    <span className={`${styles['extra-service']} d-flex center-content t-white`}>
                        <p className="t-white t-p1 t-700">{offer}</p>
                    </span>
                    <div className={`${styles['bg-cost-star']} justify-content-between center-content-y ph-2`}>
                        <p className="t-white t-p2 t-400">
                            {item.total_orders ?? 0} {lang.buy_text}
                        </p>
                        <p className="t-white t-p1 t-400 center-content">
                            {item.avg_star?.toFixed(1) ?? 0} <FaStar size={12} data-sentry-element="FaStar" data-sentry-source-file="serviceCard.index.tsx" />
                        </p>
                    </div>
                </div>
                <div className={`${styles['brand-layout']} p-2`}>
                    <p className="t-p1 t-700 t-dark1 mb-1">{item.title}</p>
                    <div className={`${styles['brand-name']} d-flex center-content-y gap-8`}>
                        <Image src={item.brand.main_image?.file ?? ''} width={24} height={24} alt={item.brand.title} data-sentry-element="Image" data-sentry-source-file="serviceCard.index.tsx" />
                        <p className="t-p2 t-400 t-dark2">{item.brand.title}</p>
                    </div>
                </div>
            </div>
        </Link>;
}
export default ServiceCardComp;