import { RestAPI, useBP } from '@/scripts';
import styles from './hiring.module.scss';
import { __HiringTabPropsType } from './hiring.type';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@/context';
import { BPEnum, HiringCategoryType, HiringType, LanguageEnum, PaginationType } from '@/types';
import { Button, Divider, message, Spin } from 'antd';
import { API, PATH } from '@/data';
import DragAbleComp from '../../dragAble/dragAble.index';
import { AppLinkComp, SliderWrapperComp } from '@/components';
import HiringCardComp from './hiringCard/hiringCard.index';
import { TbArrowLeft, TbArrowRight } from 'react-icons/tb';
export default function HiringTabContentComp(props: __HiringTabPropsType) {
  const bp = useBP();
  const {
    langText
  } = useContext(GlobalContext);
  const lang = langText.landing2;
  const isFA = langText.lang === LanguageEnum.fa;
  const [activeSlide, setActiveSlide] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<Partial<HiringCategoryType>>();
  const [hiringList, setHiringList] = useState(props.hiring);
  const [serviceCategories, setServiceCategories] = useState(props.categories);
  const sliderLayout = [{
    max_width: BPEnum.SM,
    perView: 1.3,
    spacing: 12
  }, {
    max_width: BPEnum.MD,
    perView: 2.3,
    spacing: 12
  }, {
    max_width: BPEnum.LG,
    perView: 2.3
  }, {
    max_width: BPEnum.XL,
    perView: 2.9
  }, {
    max_width: BPEnum.XXL,
    perView: 3.9,
    origin: 0.04
  }];
  const categories: Partial<HiringCategoryType>[] = [{
    title: langText.categoryList.all_category
  }, ...(serviceCategories ?? [])];
  useEffect(() => {
    // start over slider
    setActiveSlide(0);
    if (!serviceCategories) RestAPI.get<PaginationType<HiringCategoryType>>(API.hiring.categories, {
      lang: langText.lang,
      page_size: 100
    }).then(res => setServiceCategories(res.data.data));
    if (!hiringList) RestAPI.get<PaginationType<HiringType>>(API.hiring.index, {
      page_size: 20,
      category: selectedCategory?.id ?? '',
      is_active: true
    }).then(res => setHiringList(res.data));
  }, []);
  useEffect(() => {
    if (selectedCategory === undefined && props.hiring) setHiringList(props.hiring);else {
      setHiringList(undefined);
      RestAPI.get<PaginationType<HiringType>>(API.hiring.index, {
        category: selectedCategory?.id ?? '',
        is_active: true,
        page_size: 20
      }).then(res => setHiringList(res.data)).catch(() => message.error(lang.errorMessage));
    }
  }, [selectedCategory]);
  const categoryHandler = (item: Partial<HiringCategoryType>) => {
    setSelectedCategory(item);
    const tabEl = document.getElementById(`landing-category-${item.id}`);
    const scrolled = tabEl?.parentElement?.scrollLeft ?? 0;
    const dim = tabEl?.getBoundingClientRect();
    const width = dim?.width ?? 0;
    const left = dim?.left ?? 0;
    const diff = window.innerWidth / 2 - left;
    tabEl?.parentElement?.scrollTo({
      left: scrolled - diff + width / 2,
      behavior: 'smooth'
    });
  };
  const innerData = categories.filter(i => selectedCategory?.parent ? i.parent?.id === selectedCategory.parent.id : selectedCategory?.id ? i.parent?.id === selectedCategory.id : false);
  const sliderLength = hiringList?.data.length ?? 0;
  return <div className={`${styles['hiring']}`} data-sentry-component="HiringTabContentComp" data-sentry-source-file="hiring.index.tsx">
            <p>{lang.hiringHint}</p>
            <Divider className="mb-4-i d-pre-md-none-i" data-sentry-element="Divider" data-sentry-source-file="hiring.index.tsx" />
            <div>
                {serviceCategories ? <div className={`${styles['category-list']} flex-column`}>
                        <DragAbleComp parentClassName="main-content ph-4" className={`${styles['parent-category']} d-flex align-items-center pe-3 ps-2`}>
                            <span style={{
            flex: 1,
            display: 'block'
          }}></span>
                            {categories.filter(i => !i.id || !i.parent).map((item, index) => <div onClick={() => categoryHandler(item)} key={index} id={`landing-category-${item.id}`} className={`${styles['list-items']} pv-1 ${selectedCategory?.parent ? selectedCategory.parent.id === item.id ? styles['active'] : '' : selectedCategory?.id ? item.id === selectedCategory.id ? styles['active'] : '' : item.id === undefined ? styles['active'] : ''}`}>
                                        <p className="t-400 t-dark3">{item.title}</p>
                                    </div>)}
                            <span style={{
            flex: 1,
            display: 'block'
          }}></span>
                        </DragAbleComp>
                        {innerData.length ? <DragAbleComp parentClassName={`main-content ph-4 ${innerData.length ? 'mv-2' : ''}`} iconClassName="t-primary" className={`${styles['child-category']} d-flex align-items-center ph-3`}>
                                <span style={{
            flex: 1,
            display: 'block'
          }}></span>
                                {innerData.map((item, index) => <div onClick={() => setSelectedCategory(item)} key={index} className={`${styles['list-items']} p-1 ${selectedCategory?.id === item.id ? styles['active'] : ''}`}>
                                        <p className="t-400">{item.title}</p>
                                    </div>)}
                                <span style={{
            flex: 1,
            display: 'block'
          }}></span>
                            </DragAbleComp> : null}
                    </div> : null}
                {hiringList ? <SliderWrapperComp items={hiringList.data} child={HiringCardComp} noLoop origin={0.03} loadingHeight={315} className={`pt-4 ${styles['slider-content']}`} hideArrow={bp?.MD_LTE ? false : true} activeSlide={activeSlide} onSlideChange={e => setActiveSlide(e)} layout={sliderLayout} /> : <div className={`${styles['loading-box']} center-content w-100`}>
                        <Spin />
                    </div>}
                <div className={`${styles['arrow-btn']} center-content mt-4 gap-32`}>
                    <span onClick={() => setActiveSlide(isFA ? prev => prev > 0 ? prev - 1 : 0 : prev => prev < sliderLength - 1 ? prev + 1 : sliderLength - 1)} className={`pointer center-content d-pre-md-none ${isFA && activeSlide === 0 || !sliderLength ? styles['disabled'] : langText.lang === LanguageEnum.en && activeSlide === sliderLength - 1 ? styles['disabled'] : ''}`}>
                        {isFA ? <TbArrowRight color="#ffffff" /> : <TbArrowLeft color="#ffffff" />}
                    </span>
                    <AppLinkComp href={hiringList && hiringList.count === 0 ? PATH(langText.lang).hiring.index() : PATH(langText.lang).hiring.index({
          category: selectedCategory?.id?.toString()
        })} data-sentry-element="AppLinkComp" data-sentry-source-file="hiring.index.tsx">
                        <Button loading={!hiringList} className="ph-5-i" data-sentry-element="Button" data-sentry-source-file="hiring.index.tsx">
                            {hiringList && hiringList.count === 0 ? lang.seeAllHiring : bp?.MD_LTE ? lang.seeAll : lang.see + ' ' + (hiringList?.count ?? '') + ' ' + lang.hiring2}
                        </Button>
                    </AppLinkComp>
                    <span onClick={() => setActiveSlide(isFA ? prev => prev < sliderLength - 1 ? prev + 1 : sliderLength - 1 : prev => prev > 0 ? prev - 1 : 0)} className={`pointer center-content d-pre-md-none ${!sliderLength || isFA && activeSlide === sliderLength - 1 ? styles['disabled'] : langText.lang === LanguageEnum.en && activeSlide === 0 ? styles['disabled'] : ''}`}>
                        {isFA ? <TbArrowLeft color="#ffffff" /> : <TbArrowRight color="#ffffff" />}
                    </span>
                </div>
            </div>
        </div>;
}